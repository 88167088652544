import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebook,
	faWhatsapp,
	faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import classes from './Footer.module.css';
import logo from '../assets/logo.png';

function Footer() {
	return (
		<div className={classes.footer}>
			<div className={classes.wrapper}>
				<div className={classes.footerContent}>
					<div className={classes.desc}>
						<div className={classes.logo}>
							<img
								src={logo}
								alt='Kenjap Motors Logo'
							/>
						</div>
						<ul className={classes.contactList}>
							<h3>Nakuru</h3>
							<li>West Road, Adjacent to The Ole-Ken Hotel</li>
							<li>
								Phone: <a href='tel:+254701779941'> +254 701 779 941</a>
							</li>

							<h3>Nairobi - coming soon</h3>
							<li>Junction of Muthithi Road & Chiromo Lane</li>
							<li>
								Phone: <a href='tel:+254701779941'> +254 701 779 941</a>
							</li>
						</ul>
					</div>
					<div className={classes.footerLinks}>
						<div className={classes.footerSection}>
							<h3>Social Media</h3>
							<div className={classes.socialMedia}>
								<a
									href='https://www.facebook.com/kenjapmotorslimited/ '
									target='_blank'
									rel='noopener noreferrer'>
									<FontAwesomeIcon
										icon={faFacebook}
										className={classes.facebook}
									/>
								</a>
								<a
									href='https://www.instagram.com/kenjapmotors/'
									target='_blank'
									rel='noopener noreferrer'>
									<FontAwesomeIcon
										icon={faInstagram}
										className={classes.instagram}
									/>
								</a>
								<a
									href='https://wa.me/+254701779941/'
									target='_blank'
									rel='noopener noreferrer'>
									<FontAwesomeIcon
										icon={faWhatsapp}
										className={classes.whatsapp}
									/>
								</a>
							</div>
						</div>

						<div className={classes.footerSection}>
							<h3>Quick Links</h3>
							<ul>
								<li>
									<Link to='/shop/newcars'>New cars</Link>
								</li>
								<li>
									<Link to='/shop/usedcars'>Used cars</Link>
								</li>
								<li>
									<Link to='/sellyourcar'>Sell your car</Link>
								</li>
							</ul>
						</div>
						<div className={classes.footerSection}>
							<h3>Company</h3>
							<ul>
								<li>
									<Link to='/contact'>Contact us</Link>
								</li>
								<li>
									<Link to='/terms'>Terms of Use</Link>
								</li>
								<li>
									<Link to='/policy'>Privacy Policy</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<p>Copyright 2024 • All rights reserved</p>
			</div>
		</div>
	);
}

export default Footer;

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import classes from './Search.module.css';

const API_URL = `${window.location.protocol}//db.kenjap.co.ke`;

function Search() {
	const [carData, setCarData] = useState([]);
	const [makes, setMakes] = useState([]);
	const [models, setModels] = useState([]);
	const [selectedMake, setSelectedMake] = useState('');
	const [selectedModel, setSelectedModel] = useState('');
	const [selectedYear, setSelectedYear] = useState('');
	const [newUsedValues, setNewUsedValues] = useState([]);
	const [selectedNewUsed, setSelectedNewUsed] = useState('');
	const [searchResults, setSearchResults] = useState([]);

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const carResponse = await axios.get(`${API_URL}/api/vehicles`);
				const initialCars = carResponse.data;
				setCarData(initialCars);
				setMakes([...new Set(initialCars.map((car) => car.make))].sort());

				const newUsedResponse = await axios.get(
					`${API_URL}/api/vehicles/newUsed`
				);
				setNewUsedValues(newUsedResponse.data);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);

	const updateSearchResults = useCallback(
		(make, model, newUsed, year) => {
			const filteredCars = carData.filter((car) => {
				const isMakeMatch = make ? car.make === make : true;
				const isModelMatch = model ? car.model === model : true;
				const isNewUsedMatch = newUsed ? car.newUsed === newUsed : true;
				const isYearMatch = year
					? year === 'Below 2015'
						? parseInt(car.year) <= 2015
						: year === 'Current year'
						? parseInt(car.year) === new Date().getFullYear()
						: parseInt(car.year) === parseInt(year)
					: true;
				return isMakeMatch && isModelMatch && isNewUsedMatch && isYearMatch;
			});
			setSearchResults(filteredCars);
		},
		[carData]
	);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const make = queryParams.get('make') || '';
		const model = queryParams.get('model') || '';
		const newUsed = queryParams.get('newUsed') || '';
		const year = queryParams.get('year') || '';

		setSelectedMake(make);
		setSelectedModel(model);
		setSelectedNewUsed(newUsed);
		setSelectedYear(year);

		// Update search results based on query params
		updateSearchResults(make, model, newUsed, year);
	}, [location.search, updateSearchResults]);

	useEffect(() => {
		if (selectedMake) {
			const fetchModels = async () => {
				try {
					const response = await axios.get(
						`${API_URL}/api/vehicles/models/${selectedMake}`
					);
					setModels(response.data.sort());
				} catch (error) {
					console.error('Error fetching models:', error);
				}
			};
			fetchModels();
		} else {
			setModels([]);
		}
	}, [selectedMake]);

	const handleMakeChange = (event) => {
		const make = event.target.value;
		setSelectedMake(make);
		setSelectedModel('');
		updateSearchResults(make, '', selectedNewUsed, selectedYear);
	};

	const handleModelChange = (event) => {
		const model = event.target.value;
		setSelectedModel(model);
		updateSearchResults(selectedMake, model, selectedNewUsed, selectedYear);
	};

	const handleNewUsedChange = (event) => {
		const newUsed = event.target.value;
		setSelectedNewUsed(newUsed);
		updateSearchResults(selectedMake, selectedModel, newUsed, selectedYear);
	};

	const handleYearChange = (event) => {
		const year = event.target.value;
		setSelectedYear(year);
		updateSearchResults(selectedMake, selectedModel, selectedNewUsed, year);
	};

	const formatPrice = (price) => {
		return Number(price).toLocaleString('en-US', { maximumFractionDigits: 0 });
	};

	return (
		<div className={classes.search}>
			<div className={classes.wrapper}>
				<div className={classes.filters}>
					<select
						className={classes.dropdown}
						onChange={handleMakeChange}
						value={selectedMake}>
						<option value=''>Select Make</option>
						{makes.map((make) => (
							<option
								key={make}
								value={make}>
								{make}
							</option>
						))}
					</select>
					<select
						className={classes.dropdown}
						onChange={handleModelChange}
						value={selectedModel}
						disabled={!selectedMake}>
						<option value=''>Select Model</option>
						{models.map((model) => (
							<option
								key={model}
								value={model}>
								{model}
							</option>
						))}
					</select>
					<select
						className={classes.dropdown}
						onChange={handleNewUsedChange}
						value={selectedNewUsed}>
						<option value=''>Select New/Used</option>
						{newUsedValues.map((newUsed) => (
							<option
								key={newUsed}
								value={newUsed}>
								{newUsed}
							</option>
						))}
					</select>
					<select
						className={classes.dropdown}
						onChange={handleYearChange}
						value={selectedYear}>
						<option value=''>Select Year</option>
						{[
							'Below 2015',
							...Array.from(
								{ length: new Date().getFullYear() - 2015 + 1 },
								(_, index) => new Date().getFullYear() - index
							).reverse(),
							'Current year',
						].map((year, index) => (
							<option
								key={index}
								value={year}>
								{year}
							</option>
						))}
					</select>
				</div>

				<div className={classes.results}>
					{searchResults.length > 0 ? (
						searchResults.map((car, index) => (
							<div
								className={classes.vehicle}
								key={index}
								onClick={() => {
									navigate(`/car/${car.id}`);
								}}>
								<div className={classes.image}>
									{car.images && car.images.length > 0 && (
										<img
											src={`${API_URL}/images/cars/${car.images[0].imageName}`}
											alt={`${car.make} ${car.model}`}
										/>
									)}
									<p className={car.featured ? classes.offer : classes.hidden}>
										On Offer!!
									</p>
								</div>
								<div className={classes.desc}>
									<h3>{`${car.make} ${car.model}`}</h3>
									<div className={classes.descFlex}>
										<h4>
											<span>ksh. </span>
											{formatPrice(car.price)}
										</h4>
										<p
											onClick={() => {
												navigate(`/car/${car.id}`);
											}}>
											Shop now
										</p>
									</div>
								</div>
							</div>
						))
					) : (
						<p>No results found.</p>
					)}
				</div>
			</div>
		</div>
	);
}

export default Search;

import React, { useContext, useEffect, useState } from 'react';
import classes from './Profile.module.css';
import axios from 'axios';
import { AuthContext } from '../../../helpers/AuthContext';

const API_URL = `${window.location.protocol}//db.kenjap.co.ke`;

function Profile() {
	const { authState, setAuthState } = useContext(AuthContext);
	const [message, setMessage] = useState('');
	const [editing, setEditing] = useState(false);
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		password: '',
	});

	useEffect(() => {
		const fetchProfile = async () => {
			try {
				const response = await axios.get(`${API_URL}/auth/status`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				});
				const { user } = response.data;
				setAuthState((prevState) => ({
					...prevState,
					name: user.name,
					email: user.email,
				}));
				setFormData({
					name: user.name,
					email: user.email,
					password: '',
				});
			} catch (error) {
				console.error('Error fetching profile:', error);
				setMessage('Error fetching profile');
			}
		};

		fetchProfile();
	}, [setAuthState]);

	const handleEditToggle = () => {
		setEditing(!editing);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.put(
				`${API_URL}/auth/user/${authState.id}`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				}
			);
			console.log('Response data:', response.data);

			setMessage('User updated successfully');
			setEditing(false);
		} catch (error) {
			console.error('Error updating profile:', error);
			setMessage('Error updating profile');
		}
	};

	return (
		<div className={classes.profile}>
			<h1>Profile</h1>
			{!editing ? (
				<div className={classes.personalDetails}>
					<p>
						<b>Name:</b> {authState.name}
					</p>
					<p>
						<b>Email:</b> {authState.email}
					</p>
					<button onClick={handleEditToggle}></button>
				</div>
			) : (
				<form onSubmit={handleSubmit}>
					<div>
						<label htmlFor='name'>Name:</label>
						<input
							type='text'
							id='name'
							name='name'
							value={formData.name}
							onChange={handleChange}
						/>
					</div>
					<div>
						<label htmlFor='email'>Email:</label>
						<input
							type='email'
							id='email'
							name='email'
							value={formData.email}
							onChange={handleChange}
						/>
					</div>
					<div>
						<label htmlFor='password'>Password:</label>
						<input
							type='password'
							id='password'
							name='password'
							value={formData.password}
							onChange={handleChange}
						/>
					</div>
					<button type='submit'>Save</button>
					<button
						type='button'
						onClick={handleEditToggle}>
						Cancel
					</button>
				</form>
			)}
			{message && <div className={classes.message}>{message}</div>}
		</div>
	);
}

export default Profile;

import React from 'react';
import classes from './Contact.module.css';

function Contact() {
	return (
		<div className={classes.main}>
			<div className={classes.heroInvert}>
				<div className={classes.hero}>
					<h1>Contacts</h1>
				</div>
			</div>
			<div className={classes.wrapper}>
				<div className={classes.content}>
					<h2>Have a question? Let's connect</h2>
					<div className={classes.section}>
						<h3>Contact our Customer Service team</h3>
						<ul>
							<li>
								<a href='mailto:info@kenjap.co.ke'>Email: info@kenjap.co.ke</a>
							</li>
						</ul>
					</div>
					<div className={classes.section}>
						<h3>Contact the Kenjap Motors offices</h3>
						<ul>
							<li>
								<a href='tel:+254701779941'>Phone: +254 701 779 941</a>
							</li>
						</ul>
					</div>
				</div>

				<div className={classes.map}>
					<div className={classes.nakuru}>
						<h3>Nakuru</h3>
						<ul className={classes.contactList}>
							<li>West Road, Adjacent to The Ole-Ken Hotel</li>
							<li>Next to Mt. Kenya Uni. Nakuru Campus</li>
							<li>
								Phone: <a href='tel:+254701779941'> +254 701 779 941</a>
							</li>
							<li>
								Email: <a href='mailto:info@kenjap.co.ke'> info@kenjap.co.ke</a>
							</li>
						</ul>
						<div className={classes.location}>
							<iframe
								title='Kenjap Motors Location'
								src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7979.535090777874!2d36.056583982295635!3d-0.2890588702399734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x18298dbfa98dd4b9%3A0xb2fcc03c0d3c0819!2sKenjap%20Motors!5e0!3m2!1sen!2ske!4v1719986236267!5m2!1sen!2ske'
								width='100%'
								height='100%'
								style={{ border: 0 }}
								allowFullScreen=''
								loading='lazy'
								referrerPolicy='no-referrer-when-downgrade'></iframe>
						</div>
					</div>
					<div className={classes.nairobi}>
						<h3>Nairobi - coming soon</h3>
						<ul className={classes.contactList}>
							<li>Muthithi Rd, Nairobi</li>
							<li>Junction of Muthithi Road & Chiromo Lane</li>
							<li>
								Phone: <a href='tel:+254701779941'> +254 701 779 941</a>
							</li>
							<li>
								Email: <a href='mailto:info@kenjap.co.ke'> info@kenjap.co.ke</a>
							</li>
						</ul>
						<div className={classes.location}>
							<iframe
								title='Kenjap Motors Location'
								src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d997.2099370968967!2d36.80971253607755!3d-1.269013755748239!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f173c0253eac1%3A0x29059e8afb2717f!2sMuthithi%20Rd%2C%20Nairobi!5e0!3m2!1sen!2ske!4v1721030540266!5m2!1sen!2ske'
								width='100%'
								height='100%'
								style={{ border: 0 }}
								allowFullScreen=''
								loading='lazy'
								referrerPolicy='no-referrer-when-downgrade'></iframe>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contact;

import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import classes from './Register.module.css';
import axios from 'axios';

const Register = () => {
	const navigate = useNavigate();

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const API_URL = `${window.location.protocol}//db.kenjap.co.ke`;

	useEffect(() => {
		return () => {
			setShowPassword(false);
			setShowConfirmPassword(false);
		};
	}, []);

	const initialValues = {
		name: '',
		email: '',
		password: '',
		confirmPassword: '',
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.min(4, 'Name must be at least 4 characters long')
			.max(20, 'Name must be at most 20 characters long')
			.required('Required'),
		email: Yup.string().email('Invalid email address').required('Required'),
		password: Yup.string()
			.min(8, 'Password must be at least 8 characters long')
			.max(20, 'Password must be at most 20 characters long')
			.matches(/^\S*$/, 'Password cannot contain spaces')
			.matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
			.matches(/[a-z]/, 'Password must contain at least one lowercase letter')
			.matches(/[0-9]/, 'Password must contain at least one number')
			.matches(
				/[!@#$%^&*]/,
				'Password must contain at least one special character'
			)
			.required('Required'),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('password'), null], 'Passwords must match')
			.required('Required'),
	});

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	const handleSubmit = async (data, { setSubmitting, setErrors }) => {
		try {
			const response = await axios.post(`${API_URL}/auth/register`, {
				name: data.name,
				email: data.email,
				password: data.password,
			});

			if (response.data.token) {
				localStorage.setItem('token', response.data.token);
				navigate('/signin');
			}
		} catch (error) {
			console.error('There was an error!', error);
			if (error.response) {
				const errorMessage = error.response.data.error || 'Server error';
				setErrors({ email: errorMessage });
			} else if (error.request) {
				setErrors({ email: 'No response from server' });
			} else {
				setErrors({ email: 'Error sending request' });
			}
			setSubmitting(false);
		}
	};

	return (
		<div className={classes.main}>
			<div className={classes.wrapper}>
				<div className={classes.container}>
					<h2>Register</h2>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}>
						{({ isSubmitting }) => (
							<Form className={classes.form}>
								<div className={classes.formGroup}>
									<label htmlFor='name'>Name:</label>
									<Field
										type='text'
										id='name'
										name='name'
									/>
									<ErrorMessage
										name='name'
										component='p'
										className={classes.error}
									/>
								</div>
								<div className={classes.formGroup}>
									<label htmlFor='email'>Email:</label>
									<Field
										type='email'
										id='email'
										name='email'
									/>
									<ErrorMessage
										name='email'
										component='p'
										className={classes.error}
									/>
								</div>
								<div className={classes.formGroup}>
									<label htmlFor='password'>Password:</label>
									<div className={classes.passwordContainer}>
										<Field
											type={showPassword ? 'text' : 'password'}
											id='password'
											name='password'
										/>
										<button
											type='button'
											onClick={togglePasswordVisibility}
											className={classes.togglePasswordButton}
											aria-label={
												showPassword ? 'Hide password' : 'Show password'
											}>
											<FontAwesomeIcon
												className={classes.eye}
												icon={showPassword ? faEyeSlash : faEye}
											/>
										</button>
									</div>
									<ErrorMessage
										name='password'
										component='p'
										className={classes.error}
									/>
								</div>
								<div className={classes.formGroup}>
									<label htmlFor='confirmPassword'>Confirm Password:</label>
									<div className={classes.passwordContainer}>
										<Field
											type={showConfirmPassword ? 'text' : 'password'}
											id='confirmPassword'
											name='confirmPassword'
										/>
										<button
											type='button'
											onClick={toggleConfirmPasswordVisibility}
											className={classes.togglePasswordButton}
											aria-label={
												showConfirmPassword ? 'Hide password' : 'Show password'
											}>
											<FontAwesomeIcon
												className={classes.eye}
												icon={showConfirmPassword ? faEyeSlash : faEye}
											/>
										</button>
									</div>
									<ErrorMessage
										name='confirmPassword'
										component='p'
										className={classes.error}
									/>
								</div>
								<button
									type='submit'
									className={classes.registerButton}
									disabled={isSubmitting}>
									Register
								</button>
							</Form>
						)}
					</Formik>
					<p className={classes.signin}>
						Already have an account? <Link to='/signin'>Sign in here</Link>.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Register;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import classes from './Vehicles.module.css';

const API_URL = `${window.location.protocol}//db.kenjap.co.ke`;

function Vehicles() {
	const [carData, setCarData] = useState([]);
	const [sortCriteria, setSortCriteria] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const [filteredData, setFilteredData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const carResponse = await axios.get(`${API_URL}/api/vehicles`);
				const initialCars = carResponse.data;
				setCarData(initialCars);
				setFilteredData(initialCars);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const filteredCars = carData.filter((car) =>
			`${car.make} ${car.model}`
				.toLowerCase()
				.includes(searchQuery.toLowerCase())
		);
		setFilteredData(filteredCars);
	}, [searchQuery, carData]);

	const handleSortChange = (event) => {
		const criteria = event.target.value;
		setSortCriteria(criteria);
		sortResults(criteria);
	};

	const sortResults = (criteria) => {
		let sortedCars = [...filteredData];
		if (criteria === 'make') {
			sortedCars.sort((a, b) => a.make.localeCompare(b.make));
		} else if (criteria === 'model') {
			sortedCars.sort((a, b) => a.model.localeCompare(b.model));
		} else if (criteria === 'year') {
			sortedCars.sort((a, b) => b.year - a.year);
		} else if (criteria === 'mileage') {
			sortedCars.sort((a, b) => a.mileage - b.mileage);
		}
		setFilteredData(sortedCars);
	};

	return (
		<div className={classes.main}>
			<h1>Vehicles</h1>
			<div className={classes.filters}>
				<select
					className={classes.dropdown}
					onChange={handleSortChange}
					value={sortCriteria}>
					<option value=''>Sort By</option>
					<option value='make'>Make</option>
					<option value='model'>Model</option>
					<option value='year'>Year</option>
				</select>
				<input
					type='text'
					className={classes.search}
					placeholder='Search...'
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				/>
			</div>
			<div className={classes.vehicleList}>
				{filteredData.length > 0 ? (
					filteredData.map((car, index) => (
						<div
							className={classes.vehicle}
							key={index}>
							<div className={classes.image}>
								{car.images && car.images.length > 0 && (
									<img
										src={`${API_URL}/images/cars/${car.images[0].imageName}`}
										alt={`${car.make} ${car.model}`}
									/>
								)}
								<p className={car.featured ? classes.offer : classes.hidden}>
									On Offer!!
								</p>
							</div>
							<div className={classes.desc}>
								<Link to={`/dashboard/vehicles/${car.id}`}>
									<h4>{`${car.make} ${car.model}`}</h4>
								</Link>
								<div className={classes.descFlex}>
									<Link to={`/dashboard/vehicles/${car.id}`}>
										<p>{car.year}</p>
									</Link>
									<Link to={`/dashboard/vehicles/${car.id}`}>
										<p>Edit</p>
									</Link>
								</div>
							</div>
						</div>
					))
				) : (
					<p>No results found.</p>
				)}
			</div>
		</div>
	);
}

export default Vehicles;

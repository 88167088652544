import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classes from './Car.module.css';

const API_URL = `${window.location.protocol}//db.kenjap.co.ke`;

function NextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: 'block', background: 'gray' }}
			onClick={onClick}
		/>
	);
}

function PrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: 'block', background: 'gray' }}
			onClick={onClick}
		/>
	);
}

function Car() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [car, setCar] = useState(null);
	const [error, setError] = useState(null);
	const [formData, setFormData] = useState({
		fullName: '',
		email: '',
		phone: '',
		address: '',
		price: '',
		comment: '',
	});
	const [formError, setFormError] = useState(null);

	useEffect(() => {
		const fetchCarData = async () => {
			try {
				const response = await axios.get(`${API_URL}/api/vehicles/${id}`);
				setCar(response.data);
				setFormData((prevData) => ({
					...prevData,
					price: response.data.price,
				}));
			} catch (error) {
				console.error('Error fetching car data:', error);
				setError(error);
			}
		};

		fetchCarData();
	}, [id]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (car) {
			const emailData = {
				...formData,
				year: car.year,
				make: car.make,
				model: car.model,
			};
			try {
				await axios.post(`${API_URL}/api/send-email`, emailData);
				alert('Purchase form submitted successfully!');
				setFormData({
					fullName: '',
					email: '',
					phone: '',
					address: '',
					price: car.price,
					comment: '',
				});
			} catch (error) {
				console.error('Error submitting purchase form:', error);
				setFormError('Failed to submit the form. Please try again.');
			}
		}
	};

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
	};

	const formatPrice = (price) => {
		return Number(price).toLocaleString('en-US', { maximumFractionDigits: 0 });
	};

	if (error) {
		return <div>Error fetching car data: {error.message}</div>;
	}

	if (!car) {
		return <div>Loading...</div>;
	}

	return (
		<div className={classes.main}>
			<div className={classes.wrapper}>
				<div className={classes.carDetails}>
					<div className={classes.carousel}>
						{car.images && car.images.length > 0 ? (
							<Slider
								{...settings}
								className={classes.slider}>
								{car.images.map((image, index) => (
									<div key={index}>
										<img
											src={`${API_URL}/images/cars/${image.imageName}`}
											alt={`${car.make} ${car.model}`}
											className={classes.image}
										/>
									</div>
								))}
							</Slider>
						) : (
							<p>No images available</p>
						)}
					</div>
					<div className={classes.details}>
						<p>{car.newUsed}</p>
						<h2>
							{car.year} {car.make} {car.model}
						</h2>
						<p>Mileage: {formatPrice(car.mileage)} km.</p>
						<h1>
							<span>Kshs.</span> {formatPrice(car.price)}
						</h1>

						<div className={classes.moreDetails}>
							<h2>Details</h2>
							<table className={classes.table}>
								<tbody>
									<tr>
										<td>Category</td>
										<td>{car.category}</td>
									</tr>
									<tr>
										<td>Registration Number</td>
										<td>{car.registration}</td>
									</tr>
									<tr>
										<td>Exterior color</td>
										<td>{car.extColor}</td>
									</tr>
									<tr>
										<td>Interior color</td>
										<td>{car.intColor}</td>
									</tr>
									<tr>
										<td>Drivetrain</td>
										<td>{car.driveType}</td>
									</tr>
									<tr>
										<td>Fuel type</td>
										<td>{car.fuel}</td>
									</tr>
									<tr>
										<td>Transmission</td>
										<td>{car.transmission}</td>
									</tr>
									<tr>
										<td>Engine</td>
										<td>{car.engineCap} cc.</td>
									</tr>
									<tr>
										<td>Stock Type</td>
										<td>{car.stockType}</td>
									</tr>
									<tr>
										<td>Mileage</td>
										<td>{formatPrice(car.mileage)} km.</td>
									</tr>
								</tbody>
							</table>
							<h2>Description</h2>
							<p className={classes.descs}>{car.description}</p>
						</div>

						<div className={classes.contacts}>
							<h2>Have a question?</h2>
							<p>
								Get answers, see the car, or find a good time for a test drive.
								Take the next step and contact the seller.
							</p>
							<button onClick={() => navigate('/contact')}>
								Contact Seller
							</button>
						</div>
					</div>
				</div>
				<div className={classes.purchaseForm}>
					<h2>
						Purchase the {car.year} {car.make} {car.model}
					</h2>
					{formError && <p className={classes.error}>{formError}</p>}
					<form
						onSubmit={handleSubmit}
						className={classes.carForm}>
						<div>
							<label className={classes.label}>
								<p>Full Name: </p>
								<input
									type='text'
									name='fullName'
									value={formData.fullName}
									onChange={handleInputChange}
									required
								/>
							</label>
						</div>
						<div>
							<label className={classes.label}>
								<p>Email: </p>
								<input
									type='email'
									name='email'
									value={formData.email}
									onChange={handleInputChange}
									required
								/>
							</label>
						</div>
						<div>
							<label className={classes.label}>
								<p>Phone: </p>
								<input
									type='text'
									name='phone'
									value={formData.phone}
									onChange={handleInputChange}
									required
								/>
							</label>
						</div>
						<div>
							<label className={classes.label}>
								<p>Address: </p>
								<input
									type='text'
									name='address'
									value={formData.address}
									onChange={handleInputChange}
									required
								/>
							</label>
						</div>
						<div>
							<label className={classes.label}>
								<p>Price (Kshs.): </p>
								<input
									type='text'
									name='price'
									value={formatPrice(formData.price)}
									readOnly
								/>
							</label>
						</div>
						<div>
							<label className={classes.textarea}>
								<p>Comment: </p>
								<textarea
									name='comment'
									value={formData.comment}
									required
									onChange={handleInputChange}></textarea>
							</label>
						</div>
						<div className={classes.buttons}>
							<button
								type='submit'
								className={classes.submit}>
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default Car;

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

const API_URL = `${window.location.protocol}//db.kenjap.co.ke`;

export const AuthContextProvider = ({ children }) => {
	const [authState, setAuthState] = useState(() => {
		const savedAuthState = localStorage.getItem('authState');
		return savedAuthState
			? JSON.parse(savedAuthState)
			: { name: '', id: '', status: false, role: '' };
	});

	const checkAuth = async () => {
		try {
			const token = localStorage.getItem('token');
			if (!token) {
				// console.log('No token found');
				return;
			}

			const response = await axios.get(`${API_URL}/auth/status`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				withCredentials: true,
			});

			const { name, id, role } = response.data;
			setAuthState({ name, id, status: true, role });
			localStorage.setItem(
				'authState',
				JSON.stringify({ name, id, status: true, role })
			);
		} catch (error) {
			console.error('Authentication error:', error);
			setAuthState({ name: '', id: '', status: false, role: '' });
			localStorage.removeItem('authState');
			localStorage.removeItem('token');
		}
	};

	useEffect(() => {
		checkAuth();
	}, []);

	return (
		<AuthContext.Provider value={{ authState, setAuthState }}>
			{children}
		</AuthContext.Provider>
	);
};

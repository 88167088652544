import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classes from './CarDetail.module.css';

const API_URL = `${window.location.protocol}//db.kenjap.co.ke`;

function CarDetail() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [car, setCar] = useState(null);
	const [editing, setEditing] = useState(false);
	const [formData, setFormData] = useState({
		registration: '',
		year: '',
		make: '',
		model: '',
		category: '',
		newUsed: '',
		chassis: '',
		mileage: '',
		engineCap: '',
		price: '',
		extColor: '',
		intColor: '',
		driveType: '',
		fuel: '',
		transmission: '',
		stockType: '',
		description: '',
		featured: '',
	});
	const [formError, setFormError] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchCar = async () => {
			try {
				const response = await axios.get(`${API_URL}/api/vehicles/${id}`);
				setCar(response.data);
				setFormData({
					registration: response.data.registration,
					year: response.data.year,
					make: response.data.make,
					model: response.data.model,
					category: response.data.category,
					newUsed: response.data.newUsed,
					chassis: response.data.chassis,
					mileage: response.data.mileage,
					engineCap: response.data.engineCap,
					price: response.data.price,
					extColor: response.data.extColor,
					intColor: response.data.intColor,
					driveType: response.data.driveType,
					fuel: response.data.fuel,
					transmission: response.data.transmission,
					stockType: response.data.stockType,
					description: response.data.description,
					featured: response.data.featured ? 'true' : 'false',
				});
			} catch (error) {
				console.error('Error fetching car details:', error);
				setError(error);
			}
		};

		fetchCar();
	}, [id]);

	const handleDelete = async () => {
		if (window.confirm('Are you sure you want to delete this vehicle?')) {
			try {
				await axios.delete(`${API_URL}/api/vehicles/${id}`);
				navigate('/dashboard/vehicles');
			} catch (error) {
				console.error('Error deleting vehicle:', error);
				setError('Failed to delete vehicle. Please try again.');
			}
		}
	};

	const handleDeleteImage = async (imageId) => {
		if (window.confirm('Are you sure you want to delete this image?')) {
			try {
				await axios.delete(`${API_URL}/api/vehicleImages/${imageId}`);
				const response = await axios.get(`${API_URL}/api/vehicles/${id}`);
				setCar(response.data);
			} catch (error) {
				console.error('Error deleting image:', error);
				setError('Failed to delete image. Please try again.');
			}
		}
	};

	const handleEdit = () => {
		setEditing(true);
	};

	const handleCancelEdit = () => {
		setFormData({
			registration: car.registration,
			year: car.year,
			make: car.make,
			model: car.model,
			category: car.category,
			newUsed: car.newUsed,
			chassis: car.chassis,
			mileage: car.mileage,
			engineCap: car.engineCap,
			price: car.price,
			extColor: car.extColor,
			intColor: car.intColor,
			driveType: car.driveType,
			fuel: car.fuel,
			transmission: car.transmission,
			stockType: car.stockType,
			description: car.description,
			featured: car.featured ? 'true' : 'false',
		});
		setEditing(false);
		setFormError(null);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleUpdate = async () => {
		try {
			const updatedCar = {
				registration: formData.registration,
				year: formData.year,
				make: formData.make,
				model: formData.model,
				category: formData.category,
				newUsed: formData.newUsed,
				chassis: formData.chassis,
				mileage: formData.mileage,
				engineCap: formData.engineCap,
				price: formData.price,
				extColor: formData.extColor,
				intColor: formData.intColor,
				driveType: formData.driveType,
				fuel: formData.fuel,
				transmission: formData.transmission,
				stockType: formData.stockType,
				description: formData.description,
				featured: formData.featured === 'true',
			};
			await axios.put(`${API_URL}/api/vehicles/${id}`, updatedCar);
			const response = await axios.get(`${API_URL}/api/vehicles/${id}`);
			setCar(response.data);
			setEditing(false);
		} catch (error) {
			console.error('Error updating car:', error);
			setFormError('Failed to update car. Please try again.');
		}
	};

	if (error) {
		return <p>Error fetching car details: {error.message}</p>;
	}

	if (!car) {
		return <p>Loading...</p>;
	}

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
	};

	return (
		<div className={classes.carDetail}>
			<h2>Car Details</h2>
			<div className={classes.editDelete}>
				<button
					onClick={handleEdit}
					className={classes.editButton}>
					Edit
				</button>
				<button
					onClick={handleDelete}
					className={classes.deleteButton}>
					Delete Car
				</button>
			</div>
			<div className={classes.images}>
				<Slider {...settings}>
					{car.images.map((image, index) => (
						<div
							key={index}
							className={classes.imageContainer}>
							<img
								src={`${API_URL}/images/cars/${image.imageName}`}
								alt={`${car.make} ${car.model}`}
								className={classes.image}
							/>
							<button
								onClick={() => handleDeleteImage(image.id)}
								className={classes.deleteButton}>
								X
							</button>
						</div>
					))}
				</Slider>
			</div>
			<div className={classes.details}>
				{editing ? (
					<div className={classes.editForm}>
						{formError && <p className={classes.error}>{formError}</p>}
						<form
							className={classes.form}
							onSubmit={(e) => e.preventDefault()}>
							{/* Form fields */}
							{Object.keys(formData).map((key) =>
								key !== 'featured' ? (
									<div
										key={key}
										className={classes.formGroup}>
										<label>
											{key.replace(/([A-Z])/g, ' $1').toUpperCase()}:
										</label>
										<input
											type={key === 'description' ? 'textarea' : 'text'}
											name={key}
											value={formData[key]}
											onChange={handleInputChange}
										/>
									</div>
								) : (
									<div
										key={key}
										className={classes.formGroup}>
										<label>Featured:</label>
										<div className={classes.radioGroup}>
											<label>
												<input
													type='radio'
													name='featured'
													value='true'
													checked={formData[key] === 'true'}
													onChange={handleInputChange}
												/>
												True
											</label>
											<label>
												<input
													type='radio'
													name='featured'
													value='false'
													checked={formData[key] === 'false'}
													onChange={handleInputChange}
												/>
												False
											</label>
										</div>
									</div>
								)
							)}
							<button
								onClick={handleUpdate}
								className={classes.saveButton}>
								Save
							</button>
							<button
								type='button'
								onClick={handleCancelEdit}
								className={classes.cancelButton}>
								Cancel
							</button>
						</form>
					</div>
				) : (
					<div className={classes.info}>
						<p>
							<strong>Registration:</strong> {car.registration}
						</p>
						<p>
							<strong>Year:</strong> {car.year}
						</p>
						<p>
							<strong>Make:</strong> {car.make}
						</p>
						<p>
							<strong>Model:</strong> {car.model}
						</p>
						<p>
							<strong>Category:</strong> {car.category}
						</p>
						<p>
							<strong>New/Used:</strong> {car.newUsed}
						</p>
						<p>
							<strong>Chassis:</strong> {car.chassis}
						</p>
						<p>
							<strong>Mileage:</strong> {car.mileage}
						</p>
						<p>
							<strong>Engine Capacity:</strong> {car.engineCap}
						</p>
						<p>
							<strong>Price:</strong> {car.price}
						</p>
						<p>
							<strong>Exterior Color:</strong> {car.extColor}
						</p>
						<p>
							<strong>Interior Color:</strong> {car.intColor}
						</p>
						<p>
							<strong>Drive Type:</strong> {car.driveType}
						</p>
						<p>
							<strong>Fuel:</strong> {car.fuel}
						</p>
						<p>
							<strong>Transmission:</strong> {car.transmission}
						</p>
						<p>
							<strong>Stock Type:</strong> {car.stockType}
						</p>
						<p>
							<strong>Description:</strong> {car.description}
						</p>
						<p>
							<strong>Featured:</strong> {car.featured ? 'Yes' : 'No'}
						</p>
					</div>
				)}
			</div>
		</div>
	);
}

export default CarDetail;

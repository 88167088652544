import React, { useState } from 'react';
import axios from 'axios';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import classes from './AddVehicle.module.css';

// Register FilePond plugins
registerPlugin(
	FilePondPluginImagePreview,
	FilePondPluginImageExifOrientation,
	FilePondPluginFileValidateType
);

const API_URL = `${window.location.protocol}//db.kenjap.co.ke`;

const AddVehicle = () => {
	const [vehicleData, setVehicleData] = useState({
		make: '',
		model: '',
		year: '',
		newUsed: '',
		chassis: '',
		registration: '',
		stockType: '',
		category: '',
		mileage: '',
		fuel: '',
		engineCap: '',
		transmission: '',
		driveType: '',
		extColor: '',
		intColor: '',
		description: '',
		price: '',
		featured: false,
	});
	const [files, setFiles] = useState([]);
	const [feedbackMessage, setFeedbackMessage] = useState('');

	const formatPriceForDisplay = (price) => {
		if (!price) return '';
		const numericPrice = parseFloat(price.replace(/,/g, ''));
		if (isNaN(numericPrice)) return '';
		return numericPrice.toLocaleString();
	};

	const removeCommasFromPrice = (price) => {
		return price.replace(/,/g, '');
	};

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		let newValue = type === 'checkbox' ? checked : value;

		if (name === 'make') {
			newValue = newValue.trim();
		}

		if (name === 'price') {
			setVehicleData((prevData) => ({
				...prevData,
				[name]: formatPriceForDisplay(newValue),
			}));
		} else {
			setVehicleData((prevData) => ({
				...prevData,
				[name]: newValue,
			}));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Trim whitespace from each field
		const trimmedData = {};
		for (const key in vehicleData) {
			const value = vehicleData[key];
			trimmedData[key] =
				key === 'make' && typeof value === 'string' ? value.trim() : value;
		}

		try {
			// Check if chassis number exists
			const response = await axios.get(
				`${API_URL}/api/vehicles/checkChassis/${vehicleData.chassis}`
			);
			if (response.data.exists) {
				alert('Chassis number already exists.');
				return;
			}

			// Proceed with form submission
			const formData = new FormData();
			for (const key in vehicleData) {
				formData.append(
					key,
					key === 'price'
						? removeCommasFromPrice(vehicleData[key])
						: vehicleData[key]
				);
			}

			files.forEach((file, index) => {
				const imageName = `image_${index + 1}.${file.fileExtension}`;
				formData.append('images', file.file, imageName);
			});

			await axios.post(`${API_URL}/api/vehicles`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			setVehicleData({
				make: '',
				model: '',
				year: '',
				newUsed: '',
				chassis: '',
				registration: '',
				stockType: '',
				category: '',
				mileage: '',
				fuel: '',
				engineCap: '',
				transmission: '',
				driveType: '',
				extColor: '',
				intColor: '',
				description: '',
				price: '',
				featured: false,
			});
			setFiles([]);
			setFeedbackMessage('Vehicle added successfully!');
			setTimeout(() => {
				setFeedbackMessage('');
			}, 10000);
		} catch (error) {
			console.error('Error adding vehicle:', error);
		}
	};

	return (
		<div className={classes.main}>
			<h1>Add Vehicle</h1>
			{feedbackMessage && (
				<div className={classes.feedback}>{feedbackMessage}</div>
			)}
			<form
				onSubmit={handleSubmit}
				className={classes.form}>
				<div className={classes.vehicleImage}>
					<div className={classes.imageItems}>
						<p>Upload Images: </p>
						<div className={classes.filePond}>
							<FilePond
								files={files}
								allowMultiple={true}
								onupdatefiles={setFiles}
								acceptedFileTypes={['image/*']}
								labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
								className={classes.customFilePondPreview}
								itemInsertLocation='before'
							/>
						</div>
					</div>
				</div>
				<div className={classes.formFields}>
					{Object.entries(vehicleData).map(
						([key, value]) =>
							key !== 'featured' &&
							key !== 'description' && (
								<div
									key={key}
									className={classes.formField}>
									<label className={classes.label}>
										<p>{key.charAt(0).toUpperCase() + key.slice(1)}: </p>
										<input
											type={
												key === 'year' || key === 'mileage' || key === 'price'
													? 'text'
													: 'text'
											}
											name={key}
											value={key === 'price' ? vehicleData[key] : value}
											onChange={handleChange}
											className={classes.inputText}
										/>
									</label>
								</div>
							)
					)}
					<div className={classes.formField}>
						<label className={classes.description}>
							<p>Description:</p>
							<textarea
								name='description'
								value={vehicleData.description}
								onChange={handleChange}
								className={classes.textarea}
							/>
						</label>
					</div>
					<div className={classes.formField}>
						<label className={classes.featured}>
							<p>Featured: </p>
							<input
								type='checkbox'
								name='featured'
								checked={vehicleData.featured}
								onChange={handleChange}
							/>
						</label>
					</div>
					<button
						type='submit'
						className={classes.submitButton}>
						Add Vehicle
					</button>
				</div>
			</form>
		</div>
	);
};

export default AddVehicle;
